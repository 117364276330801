:root {
  --primary-light: #f9f1e9;
  --primary-dark: #ffe2ca;
  --accent: #f6a41c;
  --accent-dark: #773c19;
  --grey-text: #9d9d9d;
  --text-main: #333;
}
a {
  color: var(--primary-light) !important;
}
a.active,
a:hover {
  text-decoration: none !important;
  opacity: 0.6 !important;
}
.color-change {
  color: var(--accent-dark);
}
.form-control:focus {
  outline: none !important;
  border-color: var(--accent-dark) !important;
  box-shadow: 0 0 2px 0.5px var(--accent-dark) !important;
}
/* ################################# NAVBAR ################################# */
.nav-primary {
  background: var(--primary-light);
}
.navbar .nav-item .nav-link span.fa {
  font-size: 1.5em;
}
.round-search {
  border-top-left-radius: 0.7rem !important;
  border-bottom-left-radius: 0.7rem !important;
}
.round-input {
  border-top-right-radius: 0.7rem !important;
  border-bottom-right-radius: 0.7rem !important;
}
.fa-search::before {
  padding: 0 6px;
}
span.fa.fa-search {
  background: var(--primary-dark);
}
.nav-link .fa {
  color: var(--primary-light) !important;
}
.nav-secondary {
  background: var(--accent-dark);
  /* position: fixed !important;
  z-index: 2;
  width: 100%; */
}
.nav-primary a.nav-link {
  color: var(--accent-dark) !important;
  opacity: 1 !important;
}
.nav-primary a:hover {
  opacity: 0.8 !important;
}
#nav-section :nth-child(2) ul li.nav-item a.nav-link {
  color: var(--accent-dark) !important;
}
#nav-section :nth-child(2) ul li.nav-item a.nav-link:hover {
  opacity: 0.7 !important;
}
.btn-modal {
  background: var(--accent-dark) !important;
  color: white !important;
}
.btn-modal:hover {
  opacity: 0.8;
}
.nav-signin {
  background: transparent !important;
  color: var(--accent-dark) !important;
  padding: 0 !important;
  text-decoration: underline !important;
}
#nav-section .fa {
  color: var(--accent-dark) !important;
}
.nav-secondary .btn.btn-outline-dark {
  color: var(--primary-dark);
  border-color: var(--primary-dark);
  opacity: 1 !important;
}
.nav-secondary .btn.btn-outline-dark:active,
.nav-secondary .btn.btn-outline-dark:hover {
  background-color: var(--primary-dark) !important;
  color: var(--accent-dark) !important;
  opacity: 1 !important;
}
.nav-secondary .btn.btn-outline-dark:focus {
  box-shadow: 0;
}
.openbtn:focus {
  outline: none !important;
}
/* ################################# SIDEBAR ################################# */
.sidebar {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 100; /* Stay on top */
  top: 0;
  left: 0;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}
.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
.sidebar a:hover {
  color: #f1f1f1;
}
.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  background: transparent;
  cursor: pointer;
  border: 0;
  color: white;
}
.sidebar .closebtn:hover {
  opacity: 0.8 !important;
}
.sidebar .closebtn:focus {
  outline: none;
}
.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: transparent;
  color: var(--text-main);
  border: 1px solid var(--accent-dark);
  border-radius: 10px;
  padding: 4px 10px;
}

.openbtn:hover {
  opacity: 0.8;
}
#mainSidebar {
  transition: margin-left 0.5s; /* If you want a transition effect */
}
@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 15px;
  }
  .sidebar a {
    font-size: 18px;
  }
}
/* ################################# SLIDER ################################# */
.slider-item {
  min-height: 500px;
  max-height: 60vh;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}
.carousel-item:nth-child(1) .slider-item {
  background: url("./images/slider1.jpg");
}
.carousel-item:nth-child(2) .slider-item {
  background: url("./images/slider2.jpg");
}
.carousel-item:nth-child(3) .slider-item {
  background: url("./images/slider3.jpg");
}
.carousel-item:nth-child(4) .slider-item {
  background: url("./images/slider4.jpg");
}
.slider-item img {
  object-fit: cover;
}
.img-container {
  position: relative;
}
.slider-item h2 {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 2em;
}
.slider-item {
  transition: all 0.3s fade-in-out;
}
#Navbar .nav-item {
  margin-left: 1em;
  margin-right: 1em;
  font-size: 1.3em;
  font-weight: 600;
}
#Navbar .nav-item:hover {
  opacity: 0.6;
}
/* ################################# VED CARDS ################################# */
#cards-section {
  background: var(--primary-light);
  height: 600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 7%;
}
#cards-section a {
  color: #000 !important;
  transition: all 0.3s;
}
#cards-section a:hover {
  transform: translateY(-10px);
  box-shadow: 0px 4px 22px -7px rgba(0, 0, 0, 0.75);
  opacity: 0.8 !important;
}
.home-card .card {
  border-radius: 10px;
}
/* ################################# PRODUCTS ################################# */
#products-section div.row a.product-img-link {
  height: 150px;
  width: 100%;
  flex: 0 0 15%;
  max-width: 15%;
}
#products-section div.row :nth-child(1) .product-img {
  background: url("./images/product1.jpeg");
}
/* #products-section div.row :nth-child(1) .product-img:hover {
  filter: blur(1px);
  position: relative;
}
#products-section div.row :nth-child(1) .product-img:hover h4 {
  display: flex !important;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: absolute;
} */
#products-section div.row :nth-child(2) .product-img {
  background: url("./images/product2.jpeg");
}
#products-section div.row :nth-child(3) .product-img {
  background: url("./images/product3.jpeg");
}
#products-section div.row :nth-child(4) .product-img {
  background: url("./images/product4.jpeg");
}
#products-section div.row :nth-child(5) .product-img {
  background: url("./images/product5.jpeg");
}
#products-section div.row :nth-child(6) .product-img {
  background: url("./images/product6.jpeg");
}
#products-section div.row div.product-img {
  height: 150px;
  width: 100%;
  background-position: center!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  flex: 0 0 15%;
}
/* ################################# FOOTER ################################# */
#footer-section {
  background: var(--accent-dark);
  height: 250px;
}
#social-handles .fa {
  font-size: 2.5em;
  color: var(--primary-light);
}
#social-handles .fa:hover {
  opacity: 0.6;
}
#footer-links {
  font-size: 1.2em;
}
/* ################################# VED KENDRA ################################# */
#vedKendra {
  background: var(--primary-light);
  padding-bottom: 50px;
}
#vedKendra h2 {
  color: var(--accent-dark);
}
#vedKendra .card-title {
  color: var(--accent-dark) !important;
  text-transform: uppercase;
}
#vedKendra .card-text {
  color: var(--grey-text) !important;
  font-weight: 300 !important;
  letter-spacing: 1px;
}
#vedKendra a {
  text-transform: uppercase;
  color: var(--accent-dark) !important;
}
#vedKendra a:hover {
  color: #222 !important;
  opacity: 1 !important;
}
#vedKendra .card-body {
  z-index: 2;
  background: #fff;
}
#vedKendra .card-container {
  width: 100%;
}
.vedKendraCategory .card-img-top{
  max-width:100%;
  min-width: 100%;
}
/* ################################# VED KENDRA CATEGORIES ################################# */
.vedKendraCategory .card-text p {
  color: #000 !important;
}
.vk-product-desc {
  color: var(--grey-text);
  letter-spacing: 0.5px;
}
.vedKendraCategory .fa,
.vedKendraCategory .card-text .mb-0 {
  color: black !important;
}
.vedKendraCategory .card-text .category-price{
  color:var(--accent-dark)!important;
}
.vk-product-title{
  text-transform: capitalize;
  color:var(--text-main);
}
.vk-product-price{
  color:var(--accent-dark);
}
/* ################################# COMING SOON ################################# */
.comingSoon {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* ################################# ABOUT US SECTION ################################# */
#about-section .card .card-title {
  letter-spacing: 0.7px;
}
#about-section .card .card-text {
  letter-spacing: 0.7px;
}
/* ################################# VED MUDRA ################################# */
#vedMudra .card {
  transition: 0.2s;
}
#vedMudra .card:hover {
  transform: scale(1.1);
}
/* ################################# MEDIA QUERIES ################################# */
@media (min-width: 800px) {
  #vedKendra .card-body.card-left {
    margin-right: -25%;
  }
  #vedKendra .card-body.card-right {
    margin-left: -25%;
  }
  #vedKendra .card-container {
    height: 450px;
  }
}
@media (max-width: 800px) {
  .slider-item {
    min-height: 425px;
  }
  .slider-item:nth-child(1) {
    background: url("./images/slider/mobile/astamangal.jpg");
  }
  .slider-item:nth-child(2) {
    background: url("./images/slider/mobile/monsoon.jpg");
  }
  .slider-item:nth-child(3) {
    background: url("./images/slider/mobile/navratri.jpg");
  }
  .slider-item:nth-child(4) {
    background: url("./images/slider/mobile/saptarishi.jpg");
  }
}
